import { render, staticRenderFns } from "./listproduct.vue?vue&type=template&id=3bd5c6c1&scoped=true"
import script from "./listproduct.vue?vue&type=script&lang=js"
export * from "./listproduct.vue?vue&type=script&lang=js"
import style0 from "./listproduct.vue?vue&type=style&index=0&id=3bd5c6c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd5c6c1",
  null
  
)

export default component.exports