<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <titlesectionList :title="title" :desc1='desc1' :desc2="desc2" :link="link" :linktext="linktext" />
            <card>
               <div class="p-3">
                  <form>
                     <div class="row">
                        <div class="col-lg-4 mt-2">
                            <input type="text" class="form-control" placeholder="Barcode" v-model="barcode" @keyup.enter="searchpd($event)">
                        </div>
                        <div class="col-lg-4 mt-2">
                            <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID" @keyup.enter="searchpd($event)">
                        </div>
                        <div class="col-lg-4 mt-2">
                            <input type="text" class="form-control" placeholder="상품명" v-model="상품명" @keyup.enter="searchpd($event)">
                        </div>
                        <div class="col-lg-4 mt-2">
                            <input type="text" class="form-control" placeholder="구매링크" v-model="구매링크" @keyup.enter="searchpd($event)">
                        </div>
                        <div class="col-lg-4 mt-2">
                            <input type="text" class="form-control" placeholder="제품분류" v-model="제품분류" @keyup.enter="searchpd($event)">
                        </div>
                        <div class="col-lg-12 mt-3">
                            <button type="button" class="btn btn-primary mr-3" @click="search">검색</button>
                            <button type="reset" @click="excelDownFunc" class="btn btn-success mr-2">상품DB 다운로드</button>
                            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                        </div>

                        <div class="col-md-2 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="바코드" v-model="바코드출력" @keyup.enter="printbarcodewith($event)" v-b-tooltip.bottom="'출력할 바코드를 입력해 주세요'">
                            </div>
                        </div>
                        <div class="col-md-2 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="SKU ID" v-model="SKU출력" @keyup.enter="printSKUwith($event)" v-b-tooltip.bottom="'출력할 SKU ID를 입력해 주세요'">
                            </div>
                        </div>
                        <div class="col-md-1 mt-3">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="수량" v-model="출력수량" v-b-tooltip.bottom="'출력 수량을 입력해 주세요'">
                            </div>
                        </div>
                        <div class="col-md-1 mt-3">
                            <div class="form-group">
                                <input type="number" class="form-control" placeholder="간격" v-model="바코드간격" v-b-tooltip.bottom="'여기에 입력하는 숫자가 올라가면 출력할 바코드라벨 사이의 간격이 멀어집니다'">
                            </div>
                        </div>
                        <div class="col-md-1 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="원산지표기" v-model="원산지" v-b-tooltip.bottom="'표기할 원산지 내용을 입력해 주세요 (ex. Made in China)'">
                            </div>
                        </div>
                        <div class="col-md-5 mt-3">
                            <button type="button" class="btn btn-success mr-2" @click="printbarcoderef">바코드출력</button>
                            <div class="custom-control custom-switch custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="enabledetail">
                                <label class="custom-control-label" for="customSwitch1">한글표시사항</label>
                            </div>
                            <div class="custom-control custom-switch custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="printskuenable">
                                <label class="custom-control-label" for="customSwitch2">SKU번호 표시</label>
                            </div>
                            <div class="custom-control custom-switch custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="printkcenable">
                                <label class="custom-control-label" for="customSwitch3">KC인증마크 표시</label>
                            </div>
                        </div>

                     </div>


                        <div class="mb-4 ml-1 d-none" id="printMe8">
                                <div v-for="index in parseInt(출력수량)" :key="index">
                                    <div  class="row d-flex justify-content-center ml-1" :style="barcodegap(index,parseInt(출력수량))">
                                        <div class="col-12 d-flex flex-left mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;display:flex;justify-content: space-between;">
                                            <div class="mb-0" style="font-size:90%;color:black;">{{원산지}}</div>
                                            <div :style="printsku">SKU : {{출력SKUID}}</div>
                                        </div>
                                        <div class="col-12 text-center d-flex justify-content-center mt-0 mb-0" style="margin-top:0px;margin-bottom:0px">
                                            <barcode :value="출력바코드" :tag="bctag" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
                                        </div>
                                        <div class="col-12 mt-0 mb-0" style="margin-top:0px;margin-bottom:0px;margin-left:10px;margin-right:10px;text-align:left;line-height:5px;">
                                            <span :style="disablekr">{{출력상품명}}</span><br>
                                            <span :style="enablekr">1. 상품명 : {{출력상품명}}</span><br>
                                            <span :style="enablekr">2. 수입원/판매원 : {{한글표시사항['수입원/판매원']}}</span><br>
                                            <span :style="enablekr">3. 제조원 : {{한글표시사항.제조원}}</span><br>
                                            <span :style="enablekr">4. 제조국 : {{한글표시사항.제조국}}</span><br>
                                            <span :style="enablekr">5. 내용량 : {{한글표시사항.내용량}}</span><br>
                                            <span :style="enablekr">6. 원료명및성분명(재질) : {{한글표시사항['원료명및성분명(재질)']}}</span><br>
                                            <div style="display: flex;justify-content: space-between;">
                                                <div>
                                                    <span :style="enablekr">7. 상품유형 : {{한글표시사항.상품유형}}</span><br>
                                                    <span :style="enablekr">8. 사용시주의사항 : {{한글표시사항.사용시주의사항}}</span><br>
                                                    <span :style="enablekr">9. 사용기준 : {{한글표시사항.사용기준}}</span><br>
                                                </div>
                                                <div :style="printkc">
                                                    <img style="max-width:60%;" :src="require('../../../assets/images/small/KcMark.jpg')">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>


                  </form>
               </div>
            </card>
            </div>
            <div class="col-lg-12" v-if="showlist">
                <div class="table-responsive rounded mb-3">
                    <div>
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="selected" @change="MenuUpdate" type="radio" id="customRadio-1" name="customRadio-10" class="custom-control-input bg-primary none-height" value="radio1">
                            <label class="custom-control-label" for="customRadio-1"> Default </label>
                        </div>
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="selected" @change="MenuUpdate" type="radio" id="customRadio-2" name="customRadio-10" class="custom-control-input bg-success none-height" value="radio2">
                            <label class="custom-control-label" for="customRadio-2"> 매입공급가 </label>
                        </div>
                        <!-- <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="selected" @change="MenuUpdate" type="radio" id="customRadio-3" name="customRadio-10" class="custom-control-input bg-danger" value="radio3">
                            <label class="custom-control-label" for="customRadio-3"> 로켓판매현황 </label>
                        </div> -->
                    </div>
                </div>
                <CDataTable v-model="tableSelectedItem" :headers="selected === 'radio1' ? radio1Headers : radio2Headers" :show-select="true" :items="ProductLists" itemKey="상품명">
                    <template v-slot:상품명="{item}">
                        <div style="display: flex; align-items: center; padding: 10px 0px; line-height: 2" v-b-modal="modalID(item._id)">
                            <div>
                                <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                            </div>
                            <div>
                                <div>{{item.상품명}}</div>
                                <p class="mb-0" style="color: #676E8A;"><small>{{item["SKU ID"]}}</small></p>
                                <p class="mb-0" style="color: #676E8A;"><small>{{item.바코드}}</small></p>
                            </div>
                        </div>
                        <b-modal :id="'modal'+ item._id" size="xl" title="상품 상세 정보" ok-title="OK" cancel-title="Close">
                            <div class="col-md-6">
                                <img class="w-100 d-block" :src="item.등록이미지" style="border:1px solid silver; border-radius: 30px">
                            </div>
                            <div class="col-md-6">
                                <p style="font-size:120%">상품명 : {{item.상품명}}</p>
                                <p style="font-size:120%">SKU ID : {{item["SKU ID"]}}</p>
                                <p style="font-size:120%">바코드 : {{item.바코드}}</p>
                                <p style="font-size:120%">구매링크 : {{item.구매링크}}</p>
                                <p style="font-size:120%">옵션1_중국어 : {{item.옵션1_중국어}}</p>
                                <p style="font-size:120%">옵션2_중국어 : {{item.옵션2_중국어}}</p>
                                <p style="font-size:120%">판매구성수량 : {{item.판매구성수량}}</p>
                                <p style="font-size:120%">구매가능상태 : {{item.구매가능상태}}</p>
                                <p style="font-size:120%">매입가(최초 쿠팡공급가) : {{item.매입가 ? item.매입가 : item.현재매입가}}</p>
                                <p style="font-size:120%">현재매입가(현재 쿠팡공급가) : {{item.현재매입가}}</p>
                            </div>
                        </b-modal>
                    </template>
                    <template v-slot:판매링크="{item}">
                        <a v-if="item.판매링크" :href="item.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                    </template>
                    <template v-slot:구매링크="{item}">
                        <a v-if="item.구매링크" :href="item.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                    </template>
                    <template v-slot:actions="{item}">
                        <div class="d-flex align-items-center list-action">
                            <a class="badge badge-info mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="View" href="javascript:void(0)" v-b-modal="modalID(item._id)">
                                <i class="ri-eye-line mr-0 action-img"></i>
                            </a>
                            <a class="badge bg-success mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="Edit" href="javascript:void(0)" v-b-modal="modaledit(item._id)">
                                <i class="ri-pencil-line mr-0 action-img"></i>
                            </a>
                            <b-modal :id="'modaledit'+item._id" size="xl" title="상품 상세 정보" cancel-title="Close">
                                <card>
                                    <div class="p-3">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-12 mt-2">
                                                    <p>상품명 : {{item.상품명}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SKU ID : {{item["SKU ID"]}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;바코드 : {{item.바코드}}</p>
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">등록이미지</label>
                                                    <input type="text" class="form-control" placeholder="등록이미지" v-model="item.등록이미지">
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">구매링크</label>
                                                    <input type="text" class="form-control" placeholder="구매링크" v-model="item.구매링크">
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">옵션1_중국어</label>
                                                    <input type="text" class="form-control" placeholder="옵션1_중국어" v-model="item.옵션1_중국어">
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">옵션2_중국어</label>
                                                    <input type="text" class="form-control" placeholder="옵션2_중국어" v-model="item.옵션2_중국어">
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">판매구성수량</label>
                                                    <input type="text" class="form-control" placeholder="판매구성수량" v-model="item.판매구성수량">
                                                </div>
                                                <div class="col-lg-4 mt-2">
                                                    <label class="ml-1">구매가능상태</label>
                                                    <input type="text" class="form-control" placeholder="구매가능상태" v-model="item.구매가능상태">
                                                </div>
                                                <div class="col-lg-4 mt-4">
                                                    <button type="button" class="btn btn-primary mr-2 mb-4" @click="edit(item)">수정 및 저장</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </card>
                            </b-modal>
                            <a class="badge bg-warning mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)">
                                <i class="ri-delete-bin-line mr-0 action-img"></i>
                            </a>
                        </div>
                    </template>
                </CDataTable>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import VueBarcode from 'vue-barcode';
import CDataTable from "@/components/common/CDataTable";
export default {
    name:'Listproduct',

    data(){
        return{
            tableSelectedItem: [],
            radio1Headers: [
                {text: 'Product', value: '상품명', align: 'left', width: 100, isSlot: true},
                {text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '옵션1_중국어', value: '옵션1_중국어', align: 'left', width: 120, isSlot: false},
                {text: '옵션2_중국어', value: '옵션2_중국어', align: 'left', width: 120, isSlot: false},
                {text: '제품분류', value: '제품분류', align: 'left', width: 100, isSlot: false},
                {text: '판매구성수량', value: '판매구성수량', align: 'left', width: 120, isSlot: false},
                {text: '구매가능상태', value: '구매가능상태', align: 'left', width: 120, isSlot: false},
                {text: 'Actions', value: 'actions', align: 'left', width: 100, isSlot: true},
            ],
            radio2Headers: [
                {text: 'Product1', value: '상품명', align: 'left', width: 100, isSlot: true},
                {text: '판매링크', value: '판매링크', align: 'center', width: 80, isSlot: true},
                {text: '구매링크', value: '구매링크', align: 'center', width: 80, isSlot: true},
                {text: '발주가능상태', value: '발주가능상태', align: 'left', width: 100, isSlot: false},
                {text: '매입단가', value: '매입단가', align: 'left', width: 100, isSlot: false},
                {text: '공급가액', value: '공급가액', align: 'left', width: 100, isSlot: false},
                {text: '부가세액', value: '부가세액', align: 'left', width: 100, isSlot: false},
                {text: 'Actions', value: 'actions', align: 'left', width: 100, isSlot: true},
            ],
            title:"Product Database",
            desc1:"1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우,",
            desc2:"구매링크를 '사입'이라고 표시해 주세요",

            showlist: false,
            showspinner: false,
            barcode: '',
            SKUID: '',
            상품명: '',
            구매링크: '',
            제품분류: '',
            ProductList : {
                등록이미지: '',
                구매링크: '',
                옵션1_중국어: '',
                옵션2_중국어: '',
                판매구성수량: '',
                구매가능상태: '',
            },

            printskuenable : true,
            printkcenable : false,
            원산지 : 'Made in China',

             link:{ name: 'product.addproduct'},
             linktext:"Add Product",
            selected: 'radio1',
            menuint: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            menuDefault: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            menuCost: ["발주가능상태","매입단가","공급가액","부가세액"],
            // menuSales: ["바코드","구매링크","판매가격","판매링크","판매재고","판매상품명"],
            ProductLists: [], //this.$store.state.productdb,

            바코드출력:'',
            SKU출력:'',
            출력수량:1,
            바코드간격:0,

            enabledetail:true,

            출력SKUID:'',
            출력바코드:'',
            출력상품명:'',
            한글표시사항:{
                '수입원/판매원':'',
                제조원:'',
                제조국:'',
                내용량:'',
                '원료명및성분명(재질)':'',
                상품유형:'',
                사용시주의사항:'',
                사용기준:'',
            },
            bcvalue: '',
			bctag: 'svg',
			bcoptions: {
				lineColor: '#ff7069',
				fontSize: 50,
				font: 'Courier',
				width: 3,
				height: 60,
				marginBottom: 60,
				format: 'MSI',
				background: '#ccffff'
			},

        }
    },
    components: {
		'barcode': VueBarcode,
        CDataTable
	},
    computed : {
		enablekr(){
			if(this.enabledetail){
				return 'font-size:80%;color:black;line-height:110%;display:block;margin-bottom:0px;'
			} else {
				return 'font-size:80%;color:black;line-height:110%;display:none'
			}
		},
		disablekr(){
			if(this.enabledetail){
				return 'font-size:80%;color:black;line-height:110%;display:none'
			} else {
				return 'font-size:80%;color:black;line-height:110%;display:block'
			}
		},
        printsku(){
            if(this.printskuenable){
                return 'font-size:90%;color:black;'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        },
        printkc(){
            if(this.printkcenable){
                return 'font-size:90%;color:black;margin-left:20px;margin-top:10px'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        }
	},
    methods: {
        barcodegap(index,total){
			if(index < total){
				return 'border:0px solid silver;width:300px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
			} else {
				return 'border:0px solid silver;width:300px;text-align: center;'
			}
		},
        printbarcoderef(){
			if(this.바코드출력 != ''){
				this.printbarcode(this.바코드출력)
				// this.바코드출력 = ''
			} else {
				if(this.SKU출력 != ''){
					var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == this.SKU출력).바코드
					this.printbarcode(barcode);
					// this.SKU출력 = ''
				}
			}
		},
		printbarcodewith(event){
			if(event.keyCode === 13){
				this.printbarcode(event.target.value)
				// this.바코드출력 = ''
			}
		},
		printSKUwith(event){
			if(event.keyCode === 13){
				var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == event.target.value).바코드
				this.printbarcode(barcode);
				// this.SKU출력 = ''
			}
		},
		printbarcode(barcode){
			if(this.$store.state.productdb.length == 0 || !this.$store.state.productdb.find(e => e.바코드 == barcode)){
				// axios.post('/api/logisaddimport/importbarcodeinfo',{barcode:barcode})
				// .then((res) => {
				// 	this.출력SKUID = res.data.res[0]["SKU ID"]
				// 	this.출력바코드 = barcode;
				// 	this.출력상품명 = res.data.res[0]['상품명'];
				// 	this.한글표시사항 = {
				// 		'수입원/판매원':res.data.res[0]['수입원/판매원'],
				// 		제조원:res.data.res[0].제조원,
				// 		제조국:res.data.res[0].제조국,
				// 		내용량:res.data.res[0].내용량,
				// 		'원료명및성분명(재질)':res.data.res[0]['원료명및성분명(재질)'],
				// 		상품유형:res.data.res[0].상품유형,
				// 		사용시주의사항:res.data.res[0].사용시주의사항,
				// 		사용기준:res.data.res[0].사용기준,
				// 	}
				// 	setTimeout(() => {
				// 		this.print('printMe8');
				// 	},10)
				// })
				// .catch((e) => {alert("importbarcode 실패(1465)");console.log(e);})

				alert('상품DB에 존재하지 않은 바코드입니다. 상품DB를 업데이트한 후 다시 시도해 주세요')
				return
			} else {
				var ob = this.$store.state.productdb.find(e => e.바코드 == barcode)
				this.출력SKUID = ob['SKU ID'];
				this.출력바코드 = barcode;
				this.출력상품명 = ob['상품명'];
				this.한글표시사항 = {
					'수입원/판매원':ob['수입원/판매원'],
					제조원:ob.제조원,
					제조국:ob.제조국,
					내용량:ob.내용량,
					'원료명및성분명(재질)':ob['원료명및성분명(재질)'],
					상품유형:ob.상품유형,
					사용시주의사항:ob.사용시주의사항,
					사용기준:ob.사용기준,
				}
				setTimeout(() => {
					this.print('printMe8');
				},10)
			}

		},
        async print(id) {
			// Pass the element id here
			var options = {
				name:'_blank',
				autoClose: true,
			}
			await this.$htmlToPaper(id,options);
		},
        excelDownFunc(){
            var arr = JSON.parse(JSON.stringify(this.$store.state.productdb))
            console.log(arr)
            arr.forEach(e => {
                if(e._id){delete e._id}
                if(e.skudetail){
                    var obj = JSON.parse(e.skudetail.detail)
                    e['현재매입가'] = obj.body.price;
                }
            })
            const myHeader = ["SKU ID","요청","상품명","바코드","발주가능상태","담당 BM","발주담당자","길이 (mm)","넓이 (mm)","높이 (mm)","최소구매수량 (MOQ)","중량 (g)","Box 바코드","Inner Case 내 SKU 수량","Box 내 총수량","Pallet 1단의 SKU 수량","Pallet 내 SKU 수량","구매링크","옵션1_한국어","옵션2_한국어","옵션1_중국어","옵션2_중국어","제품분류","등록이미지","HS-CODE","판매구성수량","총구매단가","매입가","현재매입가","구매가능상태","신고영문명","신고재질","신고가격(USD)",'합포장가능여부(y/n)','수입원/판매원','제조원','제조국','내용량','원료명및성분명(재질)','상품유형','사용시주의사항','사용기준'];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, 'productDB');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, 'productDB.xlsx');
        },
        edit(ProductList){
            this.$store.state.productdb.some(e => {
                if(e["SKU ID"] == ProductList["SKU ID"]){
                    e.등록이미지 = ProductList.등록이미지;
                    e.구매링크 = ProductList.구매링크;
                    e.옵션1_중국어 = ProductList.옵션1_중국어;
                    e.옵션2_중국어 = ProductList.옵션2_중국어;
                    e.판매구성수량 = ProductList.판매구성수량;
                    e.구매가능상태 = ProductList.구매가능상태;
                    axios.post('/api/addproduct/editproductdb',ProductList)
                    .then((res) => {console.log(res.data.요청결과)})
                    .catch(console.log)
                    return (e["SKU ID"] == ProductList["SKU ID"])
                }
            })
        },
        MenuUpdate() {
            console.log(this.selected)
            if (this.selected == "radio1") {
                this.menuint = this.menuDefault;
            } else {
                if (this.selected == "radio2") {
                    this.menuint = this.menuCost;
                }
                //  else {
                //     this.menuint = this.menuSales;
                // }
            }
        },
        modalID(index) {
            return 'modal' + index
        },
        modaledit(index) {
            return 'modaledit' + index
        },
        searchpd(event) {
            if(event.keyCode === 13){
                this.search();
            }
        },
        search() {
            this.showspinner = true;
            setTimeout(() => {
                this.ProductLists = [];
                // console.log(this.barcode)
                // console.log(this.barcode.length)
                if(this.barcode.length > 0){
                    this.$store.state.productdb.some(e => {
                        if(e.바코드 && e.바코드.includes(this.barcode)){
                            this.ProductLists.push(e)
                            this.showlist=true;
                            this.barcode = '';
                            return (e.바코드.includes(this.barcode))
                        }
                    })
                } else {
                    if(this.SKUID.length > 0){
                        this.$store.state.productdb.some(e => {
                            if(e["SKU ID"] && e["SKU ID"].toString().includes(this.SKUID)){
                                this.ProductLists.push(e)
                                this.showlist=true;
                                this.SKUID = '';
                                return (e["SKU ID"].includes(this.SKUID))
                            }
                        })
                    } else {
                        if(this.상품명.length > 0){
                            this.$store.state.productdb.some(e => {
                                if(e.상품명 && e.상품명.includes(this.상품명)){
                                    this.ProductLists.push(e)
                                    this.showlist=true;
                                    this.상품명 = '';
                                    return (e.상품명.includes(this.상품명))
                                }
                            })
                        } else {
                            if(this.구매링크.length > 0){
                                this.$store.state.productdb.some(e => {
                                    if(e.구매링크 && e.구매링크.includes(this.구매링크)){
                                        this.ProductLists.push(e)
                                        this.showlist=true;
                                        this.구매링크='';
                                        return (e.구매링크.includes(this.구매링크))
                                    }
                                })
                            } else {
                                if(this.제품분류.length > 0){
                                    this.$store.state.productdb.some(e => {
                                        if(e.제품분류 && e.제품분류.includes(this.제품분류)){
                                            this.ProductLists.push(e)
                                            this.showlist=true;
                                            this.제품분류='';
                                            return (e.제품분류.includes(this.제품분류))
                                        }
                                    })
                                } else {

                                    this.ProductLists = this.$store.state.productdb;
                                    this.showlist=true;
                                }
                            }
                        }
                    }
                }
                this.showspinner = false;
            }, 1);

        }
    }
}
</script>
<style scoped>
.modal-dialog {
    max-height: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.action-button {
    width: 30px;
    height: 30px;
}
.action-img {
    display: flex;
    justify-content: center;
    margin: 6px 0px;
}
</style>

